import React from 'react'

const Dashboard = () => {
  return (
    <>
      <div className='text-center'><h2>Welcome to Bajrang Vahini Dal Admin Panel!!!!!</h2></div>
    </>
  )
}

export default Dashboard